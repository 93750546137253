body {
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  background: rgb(227,227,227);
  background: -moz-linear-gradient(0deg, rgba(227,227,227,1) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(227,227,227,1) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(0deg, rgba(227,227,227,1) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e3e3e3",endColorstr="#ffffff",GradientType=1);
}

.banner {
  background-image: url('../assets/banner.webp');
  background-size: cover;
  height: 16rem;
  width: 100%;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10rem;
  background: black;
  color: white;
  width: 100%;
}

.social {
  display: flex;
  width: 25%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.social-links > a {
  margin-right: 15px;
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.copyright {
  margin-bottom: 15px;
}

.social-img {
  width: 55px;
}

.dec-line {
  height: 0px;
  width: 90%;
  border-top: 2px solid rgba(44, 44, 44, 0.486);
  border-bottom: 2px solid black;
  margin-bottom: 25px;
}

@media only screen and (max-width: 850px) {
  .banner {
    height: 9rem;
  }

  .social {
    flex-direction: column;
    align-items: center;
    width: 95%;
  }

  .connect {
    margin-block: 20px;
  }
}
